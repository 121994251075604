footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625rem;
	}
}

//Footer Top Rows
.footer-top {
	background-color: $brandcolor1;
	padding: 2.5rem 1.25rem;
	.footer-top-inner {
		.footer-info-container {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			.footer-info-inner-container {
				color: $white;
				font-size: 0.875em;
				line-height: 1;
				text-align: center;
				@media (max-width: 991.9px) {
					font-size: 1.0em;
					margin-bottom: 2.5rem;
				}
				@media (max-width: 575.9px) {
					font-size: 1.125em;
				}
				b, strong {
					font-weight: 600;
					color: $white;
				}
				a {
					color: $white;
					&:hover {
						color: $brandcolor1-white2;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-logo-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0;
					padding: 0;
					.footer-logo {
						display: block;
						width: 10.0rem;
						height: 7.75rem;
						background: center center/contain no-repeat;
						text-indent: -9999px;
						margin: 0;
						// @media (max-width: 991.9px) {
						// 	width: 225px;
						// 	height: 43px;
						// }
						// @media (max-width: 767.9px) {
						// 	width: 300px;
						// 	height: 57px;
						// }
					}
				}
				.info-address {
					margin-top: 1.25rem;
					white-space: nowrap;
				}
				.info-phone {
					margin-top: 1.25rem;
					white-space: nowrap;
					a {
						white-space: nowrap;
					}
				}
			}
		}
		.footer-about-container, .footer-review-container {
			@media (max-width: 991.9px) {
				margin-bottom: 2.5rem;
			}
			.footer-headline, h6 {
				margin-bottom: 0.625rem;
				font-size: 1.0em;
				color: $white;
			}
			h6 {
				margin-top: 1.25rem;
			}
			p {
				font-size: 0.875em;
				line-height: 1.5;
				color: $white;
				a {
					color: $white;
					text-decoration: underline;
					&:hover {
						color: $brandcolor1-white2;
						text-decoration: underline;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: underline;
					}
				}
				b {
					color: $white;
				}
			}
		}
		.footer-review-container {
			.footer-review-content-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				//gap: 0.625rem;
				@media (max-width: 991.9px) {
				}
				@media (max-width: 767.9px) {
					align-items: center;
					text-align: center;
				}
				@media (max-width: 575.9px) {
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
				}
				.footer-review-image {
					width: 100%;
					max-width: 6.25rem;
				}
				.footer-review-copy {
					p {
						margin-bottom: 0.625rem;
					}
				}
				.btn-sm {
					font-size: 0.75rem;
					line-height: 1;
					padding: 0.625em 1.0em 0.5em 1.0em;
				}
				.info-phone-label {
					font-size: 0.875rem;
					font-weight: 700;
				}
				.info-phone-icon-container {
					white-space: nowrap;
					.info-phone-icon {
						margin-left: -3.0rem;
						width: 5.0rem;
						height: auto;
					}
					.btn-info-phone {
						white-space: nowrap;
						padding-right: 2.5rem;
						&::after {
							content: '';
							display: none;
						}
					}
				}
			}
		}
		.footer-callouts-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@media (max-width: 991.9px) {
				justify-content: flex-start;
			}
			@media (max-width: 767.9px) {
				justify-content: center;
			}
			.footer-callouts {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 2.5rem;
				.footer-callout {
					.footer-callout-logo {
						max-height: 12.5rem;
						max-width: 12.5rem;
					}
				}
			}
		}
	}
}


// Footer Middle Rows
.footer-middle {
	background-color: $brandcolor1-dark;
	position: relative;
	z-index: 1;
	.footer-middle-inner {
		padding: 1.25rem 2.5rem 0 2.5rem;
		.footer-license-info {
			padding: 1.25rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			.footer-affiliation-title {
				margin-bottom: 0;
				font-size: 1.0rem;
				color: $brandcolor1-white1;
				max-width: 12.5rem;
				@media (max-width: 575.9px) {
					max-width: 100%;
				}
			}
			.footer-affiliation-text {
				font-size: 1.25rem;
				font-weight: 700;
				color: $white;
			}
		}
		.footer-affiliation {
			padding: 1.25rem;
			display: flex;
			justify-content: center;
			align-items: center;
			.footer-affiliation-logo {
				max-height: 7.5rem;
				max-width: 16.25rem;
			}
		}
	}
}



// Footer Bottom Rows
.footer-bottom {
	background-color: $brandcolor1-dark;
	position: relative;
	z-index: 1;
	.footer-bottom-inner {
		padding: 1.25rem 2.5rem 5rem 2.5rem;
		text-align: center;
		.footer-copyright {
			color: $white;
			font-size: 0.75em;
			line-height: 1;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
		}
		.footer-links {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			ul {
				margin: 0;
				padding: 0;
				li.list-inline-item {
					margin: 0;
					padding: 0 0.3125em;
					@media (max-width: 575.9px) {
						padding: 0.3125rem 1.25em;
					}
					&:not(:last-child) {
						@media (min-width: 394.9px) {
							border-right: 1px solid $brandcolor1;
						}
					}
					a {
						font-family: $font-nav1;
						font-size: 0.75em;
						line-height: 1;
						color: $white;
						padding: 0.3125rem;
						&:hover {
							color: $brandcolor1-light2;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-light;
							text-decoration: none;
						}
					}
				}
			}
			.footer-social-icons {
				margin: 0;
				padding: 0;
				@media (min-width: 394.9px) {
					padding-left: 0.3125rem;
					border-left: 1px solid $brandcolor1-light;
				}
				@media (max-width: 575.9px) {
					padding: 0.3125rem 1.25em;
				}
				.footer-social-icon {
					margin: 0;
					padding: 0.625rem;
					color: $white;
					font-size: 1.25em;
					line-height: 1;
					@media (max-width: 575.9px) {
						padding: 0.625rem 1.25rem;
					}
					&:hover {
						color: $brandcolor1-white2;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-social-desc {
					display: none;
				}
			}
		}
	}
}
