header {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	// width: 100%;
	z-index: 1030;
	background-color: $white;
	box-shadow:
		0px 2.8px 2.2px rgba(0, 0, 0, 0.008),
		0px 6.7px 5.3px rgba(0, 0, 0, 0.012),
		0px 12.5px 10px rgba(0, 0, 0, 0.015),
		0px 22.3px 17.9px rgba(0, 0, 0, 0.018),
		0px 41.8px 33.4px rgba(0, 0, 0, 0.022),
		0px 100px 80px rgba(0, 0, 0, 0.03)
	;
	.header-top-container {
		position: relative;
		margin: 0;
		padding: 0;
		width: 100vw;
		.header-info-container {
			padding: 1.25em 1.875em;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 0.625em;
			color: $gray-dark2;
			b, strong {
				color: $brandcolor2;
			}
			.info-phone-label {
				font-size: 0.875rem;
				font-weight: 700;
			}
			.info-phone-icon-container {
				.info-phone-icon {
					margin-left: -3.0rem;
					width: 5.0rem;
					height: auto;
				}
				.btn-info-phone {
					white-space: nowrap;
					padding-right: 2.5rem;
					&::after {
						content: '';
						display: none;
					}
				}
			}
			@media (max-width: 767.9px) {
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
			}
			@media (max-width: 575.9px) {
			}
		}
		.header-logo-container {
			display: block;
			margin: 0;
			padding: 0 1.875em;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 991.9px) {
				// padding: 0;
				// text-align: center;
				// justify-content: center;
			}
			.header-logo {
				margin: 1.25rem 0;
				padding: 0;
				display: inline-block;
				width: 100%;
				height: 110px;
				background: left center/contain no-repeat;
				text-indent: -9999px;
				pointer-events: initial;
				@media (max-width: 1080px) {
					width: 100%;
					height: 110px;
					margin: 2.125em 0em 1.25em 0em;
				}
				@media (max-width: 991.9px) {
					width: 100%;
					height: 105px;
					margin: 1.25em 0em;
				}
				@media (max-width: 767.9px) {
					width: 100%;
					height: 100px;
					margin: 0.625em 0em;
					margin-left: auto;
					margin-right: auto;
				}
				@media (max-width: 575.9px) {
					// width: 100%;
					// height: 25px;
				}
			}
		}
		.header-nav-container {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $brandcolor1-white1;
			@media (max-width: 991.9px) {
				// padding: 0.625rem 0;
				text-align: center;
				justify-content: center;
			}
			.navbar {
				margin: 0;
				padding: 0;
			}
		}
	}
}
