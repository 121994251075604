.entry-pages-contact {
	.page-title-container {
		padding-bottom: 3.125em;
	}
	.contact-form-container {
		padding: 0 1.875em 5.0em 1.875rem;
		.form-well-personal {

		}
		.form-well-project {
			// background-color: $brandcolor1-white0;
			// border-color: $brandcolor1-white3;
		}
		.form-well-followup {

		}
	}
	.contact-location-info-container {
		padding: 0 0 0 5.0em;
		.contact-location-info-sticky {
			padding-top: 1.25rem;
		}
		.info-title {
			color: $brandcolor1-dark;
		}
		.info-address-phone {
			font-size: 1.125rem;
			font-size: clamp(0.875rem, -webkit-calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
			font-size: clamp(0.875rem, -moz-calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
			font-size: clamp(0.875rem, calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
			line-height: 1.125;
			b, strong {
			}
			a {
				color: $gray-dark2;
				&:hover {
					color: $brandcolor1-light2;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-light;
					text-decoration: none;
				}
			}
			.info-phone {
				margin-bottom: 2.5rem;
				white-space: nowrap;
			}
			.info-fax {
				margin-bottom: 2.5rem;
				white-space: nowrap;
			}
			.info-address {
				margin-bottom: 2.5rem;
			}
			.info-hours {
				color: $gray-dark2;
				margin-bottom: 2.5rem;
				b, strong {
					color: $gray-dark2;
				}
			}
			.info-emergency {
				margin-bottom: 2.5rem;
				white-space: nowrap;
				.info-title, a {
					color: $brandcolor2;
				}
			}
		}
		.info-social {
			.info-social-icons {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 0.625rem;
				padding-top: 0.3125rem;
				padding-bottom: 7.5rem;
				.footer-social-icon {
					color: $gray-mid2;
					font-size: 2.0rem;
					line-height: 1;
					@media (max-width: 991.9px) {
						// padding: 0.625em 2.5em;
					}
					&:hover {
						color: $brandcolor1-light2;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1-light;
						text-decoration: none;
					}
				}
				.footer-social-desc {
					display: none;
				}
			}
		}
	}
}