.link-text-nav, .link-text-subnav {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 600;
	font-size: 1.0rem;
	font-size: clamp(0.6875rem, calc(0.6875rem + 0.3125 * ((100vw - 62em) / 13)), 1.0rem);
	font-size: clamp(0.6875rem, -webkit-calc(0.6875rem + 0.3125 * ((100vw - 62em) / 13)), 1.0rem);
	font-size: clamp(0.6875rem, -moz-calc(0.6875rem + 0.3125 * ((100vw - 62em) / 13)), 1.0rem);
	line-height: 1;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark !important;
		text-decoration: none;
	}
	.fac {
		color: $brandcolor1;
	}
}

.link-text-subnav {
	color: $gray-dark2 !important;
	font-family: $font-nav1;
	font-weight: 600;
	font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 62em) / 13)), 1.125rem);
	font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 62em) / 13)), 1.125rem);
	font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 62em) / 13)), 1.125rem);
	.fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 600;
	font-size: 0.875em;
	font-size: clamp(0.5em, calc(0.5em + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	line-height: 1;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

.nav-tabs {
	position: relative;
	z-index: 50;
}

.nav-sub-inline {
	@media (max-width: 575.9px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.list-inline-item {
		margin: 0 0.625rem;
		@media (max-width: 575.9px) {
			display: block;
			margin: 0;
			padding: 1.0rem 0;
			width: 100%;
			text-align: center;
			&:not(:last-child) {
				border-bottom: 1px solid $gray-white1;
			}
		}
	}
}

.btn-nav-accordion-button {
	margin: 0;
	padding: 1.25rem 0 !important;
	border: none !important;
	border-radius: 0 !important;
	border-bottom: 1px solid $black !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $brandcolor1;
	}
	.category-nav-text {
		display: inline-block;
		margin: 0;
		padding: 0;
		color: $black;
		line-height: 1;
		@extend .link-text-subnav;
	}
}

header {
	nav {
		margin: 0;
		width: 100%;
		.navbar-collapse {
			.navbar-nav {
				margin: 0 1.875rem;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@media (min-width: 991.9px) {
					li:hover>.dropdown-menu {
						display: block;
					}
				}
				.nav-link, .dropdown-item {
					font-family: $font-nav1;
					font-weight: 600;
					line-height: 1;
					color: $brandcolor1;
					-webkit-transition: all 0.5s ease-out;
					-moz-transition: all 0.5s ease-out;
					-ms-transition: all 0.5s ease-out;
					-o-transition: all 0.5s ease-out;
					transition: all .5s ease-out;
					&:hover {
						color: $brandcolor1-light2;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1;
						text-decoration: none;
					}
				}
				.nav-item {
					margin: 0;
					position: relative;
					.nav-link {
						position: relative;
						margin: 0;
						padding: 0.9375rem;
						padding: clamp(0.6875em, -webkit-calc(0.6875em + 0.25 * ((100vw - 36em) / 39)), 0.9375rem) !important;
						padding: clamp(0.6875em, -moz-calc(0.6875em + 0.25 * ((100vw - 36em) / 39)), 0.9375rem) !important;
						padding: clamp(0.6875em, calc(0.6875em + 0.25 * ((100vw - 36em) / 39)), 0.9375rem) !important;
						&.active {
							color: $brandcolor1-dark;
							&:hover {
								color: $brandcolor1;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								color: $brandcolor1-dark;
							}
						}
						&:hover {
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						}
					}
					&.dropdown {
						&:hover {
							.dropdown-toggle {
								color: $brandcolor1-light2;
								background-color: $brandcolor1-dark;
							}
							.dropdown-menu {
								visibility: visible;
								transform: translateY(0);
								opacity: 1;
								.dropdown-item {
									opacity: 1;
								}
							}
						}
						.dropdown-toggle {
							margin-bottom: 0 !important;
							color: $brandcolor1;
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								color: $brandcolor1-light2 !important;
							}
							&::after {
								content: "\f005";
								font-family: $font-icons2;
								display: inline-block;
								font-size: 0.5em;
								font-weight: normal;
								font-style: normal;
								line-height: 1;
								text-rendering: auto;
								border: none;
								transform: translateY(-0.125em);
								padding-left: 0.25em;
							}
							&.dropdown-toggle-mobile {
								z-index: 20;
								position: absolute;
								top: 0;
								right: 0;
								margin-left: 0;
								margin: 0 !important;
								border-left: 1px solid $brandcolor1-white2;
								&::after {
									display: inline-block;
								}
							}
							@include media-breakpoint-up(lg) {
								&::after {
									display: inline-block;
								}
							}
						}
						.dropdown-menu {
							display: block;
							visibility: hidden;
							margin: 0;
							padding: 0.625rem 0 1.25rem 0;
							border-radius: 0;
							border: none;
							background-color: $brandcolor1-dark;
							opacity: 0;
							transform: translateY(-0.625rem);
							box-shadow:
								0px 2.8px 2.2px rgba(0, 0, 0, 0.008),
								0px 6.7px 5.3px rgba(0, 0, 0, 0.012),
								0px 12.5px 10px rgba(0, 0, 0, 0.015),
								0px 22.3px 17.9px rgba(0, 0, 0, 0.018),
								0px 41.8px 33.4px rgba(0, 0, 0, 0.022),
								0px 100px 80px rgba(0, 0, 0, 0.03)
							;
							-webkit-transition: opacity 0.5s ease-out, transform 0.25s ease-out;
							-moz-transition: opacity 0.5s ease-out, transform 0.25s ease-out;
							-ms-transition: opacity 0.5s ease-out, transform 0.25s ease-out;
							-o-transition: opacity 0.5s ease-out, transform 0.25s ease-out;
							transition: opacity 0.5s ease-out, transform 0.25s ease-out;

							.dropdown-item {
								margin: 0 !important;
								padding: 0.9375em;
								padding: clamp(0.6875em, -webkit-calc(0.6875em + 0.25 * ((100vw - 32em) / 39)), 0.9375rem) !important;
								padding: clamp(0.6875em, -moz-calc(0.6875em + 0.25 * ((100vw - 32em) / 39)), 0.9375rem) !important;
								padding: clamp(0.6875em, calc(0.6875em + 0.25 * ((100vw - 32em) / 39)), 0.9375rem) !important;
								color: $white;
								width: auto;
								opacity: 0;
								// border-bottom: 1px solid rgba($brandcolor1-light, 1.0);
								// &:last-child { 
								// 	border-bottom: none;
								// }
								&.active  {
									background: none;
									color: $brandcolor1-light;
								}
								&:hover {
									background: none;
									color: $brandcolor1-light2;
								}
								&:active, &:focus, &:not(:disabled):not(.disabled):focus {
									background: none;
									color: $brandcolor1-light2;
								}
							}
							.nav-item {
								> li:hover {
									> .submenu {
										display: block;
									}
								}
							}
						}
						.submenu {
							@include media-breakpoint-up(lg) {
								display: none;
								position: absolute;
								top: 0;
								left: 100%;
								right: auto;
								&.submenu-right {
									right: 100%;
									left: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	.navbar-toggler {
		margin-left: auto;
		margin-right: auto;
		padding: 1.25rem 0;
		width: 100%;
		font-family: $font-nav1;
		font-weight: 600;
		font-size: 1.0rem;
		line-height: 1;
		color: $brandcolor1;
		border-radius: 0;
		border-bottom: 1px solid $brandcolor1-white2;
		.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
			outline: 0 !important;
			outline-offset: 0  !important;
			background-image: none !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(8,70,145,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (max-width: 991.9px) {
	.navbar-expand-lg {
		.navbar-collapse {
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw !important;
			margin: 0;
			padding: 0;
			background-color: $brandcolor1-white1 !important;
			position: relative;
			z-index: 2;
			.navbar-nav {
				margin: 0;
				padding: 0;
				flex-direction: column;
				border: none;
				float: none;
				.nav-link, a.dropdown-item {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: 1.0rem;
					text-align: center;
				}
				.nav-item {
					margin: 0;
					width: 100%;
					&:not(:last-child) {
						border-bottom: 1px solid $brandcolor1-white2;
					}
					.nav-link {
						padding: 1.125rem 1.875rem !important;
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active  {
							border-bottom: none;
						}
					}
					&.dropdown {
						display: block;
						.dropdown-toggle {
							display: block;
							background: none;
							&:hover {
								border-bottom: none;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								color: $brandcolor1-light2;
								border-bottom: none;
							}
							&::after  {
								display: none;
							}
						}
						.dropdown-menu {
							display: none;
							visibility: hidden;
							padding: 0;
							// opacity: 1;		
							// transform: translateY(0);	
							background: $white;	
							box-shadow: none;	
							&.show {
								display: block;
								visibility: visible;
								opacity: 1;		
								transform: translateY(0);
							}
							&.submenu {
								right: 100%;
								top: 0;
								// background-color: $brandcolor1-dark;
								&.submenu-right {
									// background-color: $brandcolor1-dark2;
									a.dropdown-item, a.dropdown-toggle {
										background-color: $brandcolor1-dark2;
									}
								}
							}		
							a.dropdown-item {
								margin: 0 !important;
								padding: 0.9375rem 1.875rem !important;
								opacity: 1;
								color: $brandcolor1;
								&:not(:last-child) {
									border-bottom: 1px solid $brandcolor1-white2;
								}
								&:hover {
									background-color: $gray-white1;
									border-bottom: none;
								}
								&:active, &:focus, &:not(:disabled):not(.disabled):focus {
									background-color: $gray-white3;
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
