.entry-pages-home {
	#top-contact {
		.block-inner-container {
			position: relative;
			z-index: 2000;
			transform: translateY(-2.5rem);
			.block-inner-container {
				z-index: 1;
				transform: none;
			}
		}
	}
}