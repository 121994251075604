//Adding single classes for making rounded corners and then extending them
.border-radius-rounded {
 border-radius: 1.25rem;
}

.border-radius-rounded-bottom {
	border-radius: 0 0 1.25rem 1.25rem;
}

.border-radius-rounded-top {
	border-radius: 1.25rem 1.25rem 0 0;
}

.border-radius-rounded-left {
	border-radius: 1.25rem 0 0 1.25rem;
}

.border-radius-rounded-right {
	border-radius: 0 1.25rem 1.25rem 0;
}


//Add shadow effects to div elements
.back-shadow {
	box-shadow:
		0.6px 1.1px 2.2px rgba(0, 0, 0, 0.02),
		1.3px 2.7px 5.3px rgba(0, 0, 0, 0.028),
		2.5px 5px 10px rgba(0, 0, 0, 0.035),
		4.5px 8.9px 17.9px rgba(0, 0, 0, 0.042),
		8.4px 16.7px 33.4px rgba(0, 0, 0, 0.05),
		20px 40px 80px rgba(0, 0, 0, 0.07)
	;
}

//Adding effects to blocks
.block-inner-border-radius-rounded {
	.block-inner-container {
		@extend .border-radius-rounded;
		.block-inner-container {
			border-radius: 0;
		}
	}
}
.block-inner-back-shadow {
	.block-inner-container {
		@extend .back-shadow; 
		.block-inner-container {
			box-shadow: none;
		}
	}
}
.block-inner-white {
	.block-inner-container {
		background-color: $white; 
		.block-inner-container {
			background-color: initial;
		}
	}
}
.block-inner-brandcolor1 {
	.block-inner-container {
		background-color: $brandcolor1; 
		.block-inner-container {
			background-color: initial;
		}
	}
}
